import React, { useState, useEffect, useRef } from 'react'


class ErrorPage extends React.Component {

 

  render() {


  
      return (<>
          
      </>
      
      )
     }

  
    
  }


export default ErrorPage


